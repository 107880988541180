import React, { useState } from "react";
import { Card, Button, Modal } from "react-bootstrap";
import { ImageHolder } from "..";
import * as Asset from "../../assets";
import { FaReact, FaCss3Alt, FaHtml5, FaBootstrap, FaAngular, FaSalesforce, FaJava } from "react-icons/fa";
import { SiNestjs, SiMysql } from "react-icons/si";
import styles from "./ProjectCard.module.css";

const assets = {
  "Platform Development": (
    <ImageHolder
      primarySource={Asset.PerpetualCrusades}
      secondarySource={Asset.FallbackPerpetualCrusades}
      alt="Perpetual Crusades"
      className="card-img"
    />
  ),
  "Ecommerce Product": (
    <ImageHolder
      primarySource={Asset.ProjectURefer}
      secondarySource={Asset.FallbackProjectURefer}
      alt="Project URefer"
      className="card-img"
    />
  ),
  "Supply Chain Management System": (
    <ImageHolder
      primarySource={Asset.SupplyChain}
      secondarySource={Asset.FallbackHackUMassWebsite}
      alt="HackUMass Website"
      className="card-img"
    />
  ),
  "Supply Chain Management System ": (
    <ImageHolder
      primarySource={Asset.SupplyChain1}
      secondarySource={Asset.FallbackHackUMassWebsite}
      alt="HackUMass Website"
      className="card-img"
    />
  ),
  "Salesforce Connected Platform": (
    <ImageHolder
      primarySource={Asset.Salesforce}
      secondarySource={Asset.FallbackHackUMassWebsite}
      alt="HackUMass Website"
      className="card-img"
    />
  ),
  "Salesforce UI": (
    <ImageHolder
      primarySource={Asset.Salesforce1}
      secondarySource={Asset.FallbackHackUMassWebsite}
      alt="HackUMass Website"
      className="card-img"
    />
  ),
};

const icons = {
  React: <FaReact className={styles.icon} style={{ color: "#61DAFB" }} />,
  CSS: <FaCss3Alt className={styles.icon} style={{ color: "#1572B6" }} />,
  HTML: <FaHtml5 className={styles.icon} style={{ color: "#E34F26" }} />,
  Bootstrap: (
    <FaBootstrap className={styles.icon} style={{ color: "#7952B3" }} />
  ),
  NestJS: <SiNestjs className={styles.icon} style={{ color: "#E0234E" }} />,
  MySQL: <SiMysql className={styles.icon} style={{ color: "#4479A1" }} />,
  Javascript: <FaJava className={styles.icon} style={{ color: "#4479A1" }} />,
  Angular: <FaAngular className={styles.icon} style={{ color: "#E0234E" }} />,
  Salesforce: <FaSalesforce className={styles.icon} style={{ color: "#61DAFB" }} />,

};

const ProjectCard = (props) => {
  const [modalDisplay, setModalDisplay] = useState(false);

  const handleModalOpen = () => setModalDisplay(true);
  const handleModalClose = () => setModalDisplay(false);
  return (
    <Card className={styles.container}>
      {assets[props.title]}
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>
          {props.techStack.map((tech, index) => icons[tech])}
        </Card.Text>
        <Card.Text>{props.content}</Card.Text>
        {props.liveUrl ? (
          <Button
            className={styles.button}
            variant="primary"
            href={props.liveUrl}
            target="_blank"
          >
            View Live
          </Button>
        ) : null}
        {props.gitHubUrl ? (
          <Button
            className={styles.button}
            variant="dark"
            href={props.gitHubUrl}
            target="_blank"
          >
            View on GitHub
          </Button>
        ) : null}
        {props.description ? (
          <React.Fragment>
            <Button
              className={styles.button}
              variant="secondary"
              onClick={handleModalOpen}
            >
              View More
            </Button>
            <Modal
              centered
              size="lg"
              show={modalDisplay}
              onHide={handleModalClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>{props.organization}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{ textAlign: "center" }}>
                  <h4>{props.title}</h4>
                </div>
                <div style={{ textAlign: "center" }}>
                  <h5>{props.duration}</h5>
                </div>
                <ul>
                  {props.description.map((elem) => (
                    <li>{elem}</li>
                  ))}
                </ul>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default ProjectCard;
