import React from "react";
import { Container, Row } from "react-bootstrap";
import styles from "./Header.module.css";

const Header = (props) => {
  const backgroundStyle = {
    Projects: styles.projects,
    Experience: styles.experience,
    Involvements: styles.involvements,
    Achievements: styles.achievements,
  };
  if(props.title !== 'Involvements'){
    return (
      <Container
        fluid
        className={`${styles.container} ${backgroundStyle[props.title]}`}
      >
        <Row>
          <h1 className={styles.headingOne}>{props.title}</h1>
        </Row>
      </Container>
    );
  }else{
    return (
      <Container
        fluid
        className={`${styles.container} ${backgroundStyle[props.title]}`}
      >
        <Row>
          <h1 className={styles.headingOne}>Contact Details</h1>
        </Row>
      </Container>
    );
  }
};

export default Header;
